<template>
  <router-view />
</template>
<style>
*{
  margin: 0;
  padding: 0;
}
body {
  font-family: "Arial", sans-serif;
}
</style>

