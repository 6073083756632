import { createRouter, createWebHashHistory, RouteRecordRaw,createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import About from "../views/about.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "about",
    component: About,
  },
  {
    path: "/start",
    name: "start",
    component: HomeView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
