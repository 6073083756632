<template>
  <div class="loginAdmin">
    <div class="contnet">
      <div class="top">
        <img src="../assets/logo.svg" alt="IP地址归属地查询-蓝色互联" />
      </div>
      <div class="list">
        <div class="left">
          <div class="v1">蓝色互联，</div>
          <div class="v1">提供高精准的IP地址归属地算法技术。</div>
          <div class="v2">
            <p>IP归属地是利用大数据挖掘和大规模网络探测技术，</p>
            <p>对IP地址的基础信息和网络拓扑数据进行采集和处理，</p>
            <p>结合IP地址所在的应用场景与网络属性等因素，</p>
            <p>利用动态密度聚类算法和基于多层神经网络的IP地址定位算法，</p>
            <p>完成IP地址地理位置定位。</p>
            <p>精确到区县位置，每日更新，包括运营商和归属单位等数据。</p>
          </div>
          <!-- <el-button
            type="primary"
            @click="toStart"
            color="#1B79FF"
            class="beginBtn"
          >
            现在开始查询IP归属地
          </el-button> -->
          <div class="inputItem">
            <el-input
              placeholder="请输入IP地址（支持IPV4和IPV6）"
              v-model="searchValue"
              @input="handleClear"
              autofocus="true"
              @keyup.enter.native="toStart()"
            >
              <template #suffix>
                <span @click="toStart()">查询</span>
              </template>
            </el-input>
          </div>

          <div class="titleIp" @click="toStart('本机当前IP地址')">
            获取本机当前IP地址
          </div>
        </div>
        <div class="right">
          <img src="../../public/indexImg.png" alt="IP地址归属地查询-蓝色互联" />
        </div>
      </div>
    </div>
    <div class="footer">
      <span style="margin-right: 30px" @click="open">京ICP备2023007903号 </span
      ><span @click="company">蓝色互联（北京）技术有限公司</span>提供支持
      <span style="margin-left: 20px">关于</span>
    </div>
  </div>
</template>
  <script lang="ts" setup>
import { fa } from "element-plus/es/locale";
import { ref, onMounted } from "vue";
import { ElMessage } from "element-plus";
import axios from "axios";
import { useRouter } from "vue-router";
const router = useRouter();
const apiUrlV4 = ref(config.apiUrlV4); //地址
const apiUrlV6 = ref(config.apiUrlV6); //地址
const searchValue = ref();
onMounted(() => {
  fetchPublicIpV4(); //v4
  fetchPublicIpV6(); //v6
});
// 获取本机ipv4
const fetchPublicIpV4 = () => {
  axios
    .get(apiUrlV4.value + "/web/query/getMyIp")
    .then((response) => {
      if (response.data.code == 200) {
      }
    })
    .catch((error) => {
      console.error("Error fetching public IP:", error);
    });
};
// 获取本机ipv6
const fetchPublicIpV6 = () => {
  axios
    .get(apiUrlV6.value + "/web/query/getMyIp")
    .then((response) => {
      if (response.data.code == 200) {
      }
    })
    .catch((error) => {
      console.error("Error fetching public IP:", error);
    });
};
// 清除
const handleClear = (val) => {
  if (searchValue.value) {
    searchValue.value = val.replace(/\s/g, "");
  }
};
// 首页
const toStart = (val) => {
  router.push({
    path: "/start",
    query: {
      searchValue: val ? val : searchValue.value,
    },
  });
};
// 打开京ICP
const open = () => {
  window.open("https://beian.miit.gov.cn/#/Integrated/recordQuery");
};
// 打开公司
const company = () => {
  window.open("http://www.lansehulian.com");
};
</script>
  <style lang="less" scoped>
.loginAdmin {
  .contnet {
    padding: 38px 46px;
    .top {
      img {
        width: 140px;
      }
      margin-bottom: 112px;
    }
    .list {
      display: flex;
      align-items: center;
      justify-content: center;
      .left {
        margin-right: 134px;
        .v1 {
          font-size: 30px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 0em;

          color: #3d3d3d;
        }
        .v2 {
          margin: 110px 0;
          p {
            font-size: 20px;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0em;
            color: #000000;
          }
        }
        /deep/.el-button--primary {
          width: 209px;
          height: 36px;
          border-radius: 2px;
          opacity: 1;

          box-sizing: border-box;

          font-size: 14px;
          line-height: 36px;
        }
      }
      .right {
        img {
          width: 567px;
        }
      }
    }
  }
}
@media (max-width: 850px) {
  /* 大屏适配样式 */
  .loginAdmin {
    .contnet {
      padding: 16px;
      .top {
        img {
          width: 140px;
        }
        margin-bottom: 112px;
      }
      .list {
        display: flex;
        align-items: center;
        justify-content: center;
        .left {
          margin-right: 20px;
          .v1 {
            font-size: 26px;
            font-weight: normal;
            line-height: normal;
            letter-spacing: 0em;

            color: #3d3d3d;
          }
          .v2 {
            margin: 110px 0;
            p {
              font-size: 15px;
              font-weight: 300;
              line-height: normal;
              letter-spacing: 0em;
              color: #000000;
            }
          }
          /deep/.el-button--primary {
            width: 209px;
            height: 36px;
            border-radius: 2px;
            opacity: 1;

            box-sizing: border-box;

            font-size: 14px;
            line-height: 36px;
          }
        }
        .right {
          img {
            width: 267px;
          }
        }
      }
    }
  }
}

@media (max-width: 540px) {
  /* 手机端适配样式 */
  .loginAdmin {
    .contnet {
      padding: 16px;
      .top {
        img {
          width: 140px;
        }
        margin-bottom: 30px;
      }
      .list {
        display: inline-block;
        .left {
          margin-right: 0;
          .v1 {
            font-size: 19px;
            font-weight: normal;
            line-height: normal;
            letter-spacing: 0em;
            color: #3d3d3d;
            width: 330px;
          }
          .v2 {
            margin: 40px 0;

            p {
              font-size: 15px;
              font-weight: 300;
              line-height: normal;
              letter-spacing: 0em;
              color: #000000;
            }
          }
          /deep/.el-button--primary {
            width: 209px;
            height: 36px;
            border-radius: 2px;
            opacity: 1;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 36px;
            position: absolute;
            bottom: 50px;
          }
        }
        .right {
          img {
            width: 300px;
            margin-left: 30px;
          }
        }
      }
    }
    .footer {
      font-size: 10px;
    }
  }
}

@media (max-width: 380px) {
  /* 手机端适配样式 */
  .loginAdmin {
    .contnet {
      padding: 16px;
      .top {
        img {
          width: 140px;
        }
        margin-bottom: 10px;
      }
      .list {
        display: inline-block;
        .left {
          margin-right: 0;
          .v1 {
            font-size: 19px;
            font-weight: normal;
            line-height: normal;
            letter-spacing: 0em;
            color: #3d3d3d;
            width: 330px;
          }
          .v2 {
            margin: 20px 0;

            p {
              font-size: 15px;
              font-weight: 300;
              line-height: normal;
              letter-spacing: 0em;
              color: #000000;
            }
          }
          /deep/.el-button--primary {
            width: 209px;
            height: 36px;
            border-radius: 2px;
            opacity: 1;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 36px;
            position: absolute;
            bottom: 80px;
          }
        }
        .right {
          img {
            width: 280px;
            margin-left: 30px;
          }
        }
      }
    }
    .footer {
      font-size: 9px;
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  height: 30px;
  background: #f6f6f6;
  font-size: 13px;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  color: #000000;
  line-height: 30px;
  span {
    cursor: pointer;
  }
}
.inputItem {
  width: 343px;
  span {
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0em;
    color: #1b79ff;
    line-height: 40px;
    padding-right: 0px;
    cursor: pointer;
  }
}
.titleIp {
  font-size: 13px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;
  color: #1b79ff;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
</style>
  <style>
</style>