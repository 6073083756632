<template>
  <div class="loginAdmin">
    <img class="img" src="../assets/ip.svg" alt="IP地址归属地查询-蓝色互联" />
    <div class="title">IP地址归属地查询</div>
    <div class="inputItem">
      <el-input
        placeholder="请输入IP地址（支持IPV4和IPV6）"
        v-model="searchValue"
        @input="handleClear"
        autofocus="true"
        @keyup.enter.native="search"
      >
        <template #suffix>
          <span @click="search">查询</span>
        </template>
      </el-input>
    </div>

    <div class="titleIp" @click="fetchPublicData()">获取本机当前IP地址</div>

    <div class="content" v-if="showContent && !loading">
      <div v-if="ipData.ip" class="list">
        <div>
          <span>您查询的IPV4地址：</span><span class="ip">{{ ipData.ip }}</span>
        </div>
        <div v-if="ipData.data.areacode && ipData.data.areacode != 'B1'">
          <span>国家编码: </span><span>{{ ipData.data.areacode }}</span>
          <img :src="ipData.data.imgUrl" alt="" />
        </div>
        <div>
          <span>所属区域：</span
          ><span v-if="ipData.data.continent" style="margin-right: 10px">{{
            ipData.data.continent == "保留IP"
              ? "局域网地址或保留资源"
              : ipData.data.continent
          }}</span>
          <span v-if="ipData.data.country">{{ ipData.data.country }}</span>
          <span v-if="ipData.data.prov">{{ ipData.data.prov }}</span>
          <span v-if="ipData.data.city">{{ ipData.data.city }}</span>
          <span v-if="ipData.data.district">{{ ipData.data.district }}</span>
        </div>
        <div v-if="ipData.data.owner">
          <span>所属机构：</span><span>{{ ipData.data.owner }}</span>
        </div>
        <div v-if="ipData.data.isp">
          <span>运营商名称：</span><span>{{ ipData.data.isp }}</span>
        </div>
        <div v-if="ipData.data.asnumber">
          <span>自治域编码：</span><span>{{ ipData.data.asnumber }}</span>
        </div>
        <div v-if="ipData.data.zipcode">
          <span>邮编：</span><span>{{ ipData.data.zipcode }}</span>
        </div>
        <div v-if="ipData.data.adcode">
          <span>行政区码：</span><span>{{ ipData.data.adcode }}</span>
        </div>
        <div v-if="ipData.data.timezone">
          <span>时区：</span><span>{{ ipData.data.timezone }}</span>
        </div>

        <div v-if="ipData.data.lng || ipData.data.lat">
          <span>坐标：</span><span>{{ ipData.data.lat }}</span
          ><span style="margin-left: 10px">{{ ipData.data.lng }}</span>
        </div>

        <!-- <div v-if="ipData.data.source">
          <span>采集方式：</span><span>{{ ipData.data.source }}</span>
        </div> -->

        <div>
          <span>查询时间：</span><span>{{ currentDateTime }}</span>
        </div>
      </div>
      <div
        v-if="ipData6.ip"
        :style="{
          marginTop: ipData.ip ? 40 + 'px' : 0 + 'px',
        }"
        class="list"
      >
        <div>
          <span>您查询的IPV6地址：</span
          ><span class="ip">{{ ipData6.ip }}</span>
        </div>

        <div v-if="ipData6.data.areacode && ipData6.data.areacode != 'B1'">
          <span>国家编码: </span><span>{{ ipData6.data.areacode }}</span>
          <img :src="ipData6.data.imgUrl" alt="" />
        </div>
        <div>
          <span>所属区域：</span
          ><span v-if="ipData6.data.continent" style="margin-right: 10px">{{
            ipData6.data.continent == "保留IP"
              ? "局域网地址或保留资源"
              : ipData6.data.continent
          }}</span>
          <span v-if="ipData6.data.country">{{ ipData6.data.country }}</span>
          <span v-if="ipData6.data.prov">{{ ipData6.data.prov }}</span>
          <span v-if="ipData6.data.city">{{ ipData6.data.city }}</span>
          <span v-if="ipData6.data.district">{{ ipData6.data.district }}</span>
        </div>
        <div v-if="ipData6.data.owner">
          <span>所属机构：</span><span>{{ ipData6.data.owner }}</span>
        </div>
        <div v-if="ipData6.data.isp">
          <span>运营商名称：</span><span>{{ ipData6.data.isp }}</span>
        </div>
        <div v-if="ipData6.data.asnumber">
          <span>自治域编码：</span><span>{{ ipData6.data.asnumber }}</span>
        </div>
        <div v-if="ipData6.data.zipcode">
          <span>邮编：</span><span>{{ ipData6.data.zipcode }}</span>
        </div>
        <div v-if="ipData6.data.adcode">
          <span>行政区码：</span><span>{{ ipData6.data.adcode }}</span>
        </div>
        <div v-if="ipData6.data.timezone">
          <span>时区：</span><span>{{ ipData6.data.timezone }}</span>
        </div>
        <div v-if="ipData6.data.lng || ipData6.data.lat">
          <span>坐标：</span><span>{{ ipData6.data.lat }}</span
          ><span style="margin-left: 10px">{{ ipData6.data.lng }}</span>
        </div>

        <!-- <div v-if="ipData6.data.source">
          <span>采集方式：</span><span>{{ ipData6.data.source }}</span>
        </div> -->

        <div>
          <span>查询时间：</span><span>{{ currentDateTime }}</span>
        </div>
      </div>
    </div>
    <div style="margin: 0 auto" class="skeleton" v-if="!showContent && loading">
      <el-skeleton :rows="5" />
    </div>
    <div class="footer">
      <span style="margin-right: 30px" @click="open">京ICP备2023007903号 </span
      ><span @click="company">蓝色互联（北京）技术有限公司</span>提供支持
      <span style="margin-left: 20px" @click="toIndex">关于</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { fa } from "element-plus/es/locale";
import { ref, onMounted } from "vue";
import { ElMessage } from "element-plus";
import axios from "axios";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
console.log(route.query, "route");
// =============================================================================================================
const apiUrl = ref(config.apiUrl); //地址
const apiUrlV4 = ref(config.apiUrlV4); //地址
const apiUrlV6 = ref(config.apiUrlV6); //地址
const a = ref();
const searchValue = ref();
const ipData = ref({});
const ipData6 = ref({});
const showContent = ref(false);
const loading = ref(false);
const currentDateTime = ref("");
const messageVisible = ref(false);
onMounted(() => {
  if (!route.query.searchValue) {
    fetchPublicIpV4(); //v4
    fetchPublicIpV6(); //v6
  }
  if (route.query.searchValue && route.query.searchValue == "本机当前IP地址") {
    searchValue.value = route.query.searchValue;
    fetchPublicIpV4("1"); //v4
    fetchPublicIpV6("1"); //v6
  } else if (route.query.searchValue) {
    searchValue.value = route.query.searchValue;
    getIp();
  }
});
const getTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  currentDateTime.value = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
// 查询
const search = () => {
  if (!searchValue.value) {
    messageVisible.value = true;
    ElMessage({
      message: "请输入IP地址（支持IPV4和IPV6）",
      type: "error",
      duration: 1000, // 显示时间，单位毫秒
      onClose: () => {
        messageVisible.value = false; // 消息关闭时，重置标志位
      },
    });
  } else if (searchValue.value == "本机当前IP地址") {
    queryIpv4(v4.value);
    queryIpv6(v6.value);
  } else {
    getIp();
  }
};
// 清空ip
const handleClear = (val) => {
  if (searchValue.value) {
    searchValue.value = val.replace(/\s/g, "");
  }
  showContent.value = false;
  ipData.value = {};
  ipData6.value = {};
};
// 获取ip
const getIp = (val) => {
  let ip = "";
  ip = searchValue.value;
  let ipv4Regex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
  let ipv6Regex = /^([\dA-Fa-f]{1,4}:){7}[\dA-Fa-f]{1,4}$/;
  let ipType = "";
  if (ipv4Regex.test(ip)) {
    ipType = "IPv4";
    queryIpv4(ip);
  } else if (ipv6Regex.test(ip)) {
    ipType = "IPv6";
    queryIpv6(ip);
  } else {
    queryIpv6(ip);
    // ipType = "Neither IPv4 nor IPv6";
    // ElMessage({
    //   message: "你输入的IP格式不正确！",
    //   type: "error",
    // });
  }
  return;
};
// 获取v4
const queryIpv4 = (ip) => {
  loading.value = true;
  axios
    .get(apiUrl.value + "/web/query/queryIpv4?ip=" + ip)
    .then((response) => {
      if (response.data.code == 200 || response.data.code == "Success") {
        showContent.value = true;
        getTime();
        loading.value = false;
        ipData.value = response.data;
        if (ipData6.value.ip && ipData.value.ip) {
          searchValue.value = "本机当前IP地址";
        } else {
          searchValue.value = response.data.ip;
        }
        if (ipData.value.data.areacode != "B1") {
          let url = "";
          if (ipData.value.data.areacode) {
            url = require("@/assets/flag/" +
              ipData.value.data.areacode.toLowerCase() +
              ".svg");
          }
          if (url) {
            ipData.value.data.imgUrl = url;
          } else {
            ipData.value.data.imgUrl = "";
          }
        }
      } else {
        loading.value =false;
        ElMessage({
          message: response.data.msg,
          type: "error",
        });
      }
    })
    .catch((error) => {
      console.error("Error fetching public IP:", error);
    });
};
// 获取v6
const queryIpv6 = (ip) => {
  loading.value = true;
  getTime();
  axios
    .get(apiUrlV4.value + "/web/query/queryIpv6?ip=" + ip)
    .then((response) => {
      if (response.data.code == 200 || response.data.code == "Success") {
        showContent.value = true;
        loading.value = false;
        ipData6.value = response.data;
        if (ipData6.value.ip && ipData.value.ip) {
          searchValue.value = "本机当前IP地址";
        } else {
          searchValue.value = response.data.ip;
        }
        if (ipData6.value.data.areacode != "B1") {
          let url = "";
          if (ipData6.value.data.areacode) {
            url = require("@/assets/flag/" +
              ipData6.value.data.areacode.toLowerCase() +
              ".svg");
          }
          if (url) {
            ipData6.value.data.imgUrl = url;
          } else {
            ipData6.value.data.imgUrl = "";
          }
        }
      } else {
        loading.value =false;
        ElMessage({
          message: response.data.msg,
          type: "error",
        });
      }
    })
    .catch((error) => {
      console.error("Error fetching public IP:", error);
    });
};
// 调用本机
const fetchPublicData = () => {
  fetchPublicIpV4("1"); //v4
  fetchPublicIpV6("1"); //v6
};
const v4 = ref();
const v6 = ref();
// 获取本机ipv4
const fetchPublicIpV4 = (type) => {
  axios
    .get(apiUrlV4.value + "/web/query/getMyIp")
    .then((response) => {
      if (response.data.code == 200) {
        if (type) {
          v4.value = response.data.data;
          let ipv4Regex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
          if (ipv4Regex.test(response.data.data)) {
            queryIpv4(response.data.data);
          } else {
            console.log("格式不正确");
          }
        }
      }
    })
    .catch((error) => {
      console.error("Error fetching public IP:", error);
    });
};
// 获取本机ipv6
const fetchPublicIpV6 = (type) => {
  axios
    .get(apiUrlV6.value + "/web/query/getMyIp")
    .then((response) => {
      if (response.data.code == 200) {
        if (type) {
          v6.value = response.data.data;
          let ipv6Regex = /^([\dA-Fa-f]{1,4}:){7}[\dA-Fa-f]{1,4}$/;
          if (ipv6Regex.test(response.data.data)) {
            queryIpv6(response.data.data);
          } else {
            console.log("格式不正确");
          }
        }
      }
    })
    .catch((error) => {
      console.error("Error fetching public IP:", error);
    });
};
// 打开京ICP
const open = () => {
  window.open("https://beian.miit.gov.cn/#/Integrated/recordQuery");
};
// 打开公司
const company = () => {
  window.open("http://www.lansehulian.com");
};
// 首页
const toIndex = () => {
  router.push("/");
};
</script>
<style lang="less" scoped>
.loginAdmin {
  background: #ffffff;
  overflow-y: auto;
  width: 100%;
  text-align: center;
  padding-bottom: 100px;
  .img {
    height: 50px;
    padding-top: 130px;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: #3d3d3d;
    padding-bottom: 20px;
    padding-top: 30px;
  }
  .titleIp {
    font-size: 13px;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0em;
    color: #1b79ff;
    margin-top: 15px;
    margin-bottom: 36px;
    cursor: pointer;
    width: 160px;
    margin: 15px auto 36px;
  }
  .inputItem {
    width: 443px;
    margin: 0 auto;
    text-align: center;
    span {
      font-size: 16px;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0em;
      color: #1b79ff;
      line-height: 40px;
      padding-right: 0px;
      cursor: pointer;
    }
  }
  .skeleton {
    width: 460px;
    /deep/ .el-skeleton {
      width: 100%;
      text-align: left;
      --el-skeleton-color: #1b79ff33;
      --el-skeleton-to-color: var(--el-fill-color-dark);
    }
  }
  @media (min-width: 768px) {
    /* 大屏适配样式 */
  }

  @media (max-width: 540px) {
    /* 手机端适配样式 */
    .inputItem {
      width: 280px;
      margin: 0 auto;
      text-align: center;
      span {
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0em;
        color: #1b79ff;
        line-height: 40px;
        padding-right: 0px;
        cursor: pointer;
      }
    }
    .skeleton {
      width: 200px;
    }
    .img {
      height: 50px;
      padding-top: 30px;
    }
    .titleIp {
      font-size: 13px;
      font-weight: normal;
      line-height: normal;
      text-align: center;
      letter-spacing: 0em;
      color: #1b79ff;
      margin-bottom: 20px;
      cursor: pointer;
      width: 160px;
      margin: 15px auto 15px;
    }
  }

  .content {
    width: 600px;
    border-radius: 2px;
    opacity: 1;
    background: #f9f9f9;
    box-sizing: border-box;
    border: 0.25px solid #1b79ff;
    animation: glow 1s ease-in-out infinite alternate;
    margin: 0 auto;
    margin-bottom: 20px !important;
    padding: 10px 0 10px 15px;
    .list {
      div {
        padding-bottom: 0px;
        line-height: 0;
        text-align: left;
        display: flex;
        span:nth-child(1) {
          font-size: 13px;
          font-weight: 350;
          line-height: normal;
          letter-spacing: 0em;
          font-variation-settings: "opsz" auto;
          font-feature-settings: "kern" on;
          color: #7e7e7e;
          width: 130px;
          display: inline-block;
          text-align: left;
        }
        span {
          font-size: 13px;
          font-weight: 350;
          line-height: normal;
          letter-spacing: 0em;
          font-variation-settings: "opsz" auto;
          font-feature-settings: "kern" on;
          color: #000000;
        }
        .ip {
          display: inline-block;
          overflow-wrap: break-word;
          width: 450px;
        }
      }
    }

    img {
      width: 16px;
      margin-left: 5px;
    }
  }
  @media (max-width: 540px) {
    /* 手机端适配样式 */
    .content {
      width: 340px;
      border-radius: 2px;
      opacity: 1;
      background: #f9f9f9;
      box-sizing: border-box;
      border: 0.25px solid #1b79ff;
      animation: glow 1s ease-in-out infinite alternate;
      margin: 0 auto;
      margin-bottom: 20px !important;
      padding: 5px 0 5px 12px;
      .list {
        div {
          padding-bottom: 0px;
          line-height: 0px;
          text-align: left;
          display: flex;

          span:nth-child(1) {
            font-size: 10px;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0em;
            font-variation-settings: "opsz" auto;
            font-feature-settings: "kern" on;
            color: #7e7e7e;
            width: 93px;
            display: inline-block;
            text-align: left;
          }
          span {
            font-size: 10px;
            font-weight: 350;
            line-height: normal;
            letter-spacing: 0em;

            font-variation-settings: "opsz" auto;
            font-feature-settings: "kern" on;
            color: #000000;
          }
          .ip {
            display: inline-block;
            overflow-wrap: break-word;
            width: 220px;
          }
        }
      }
      img {
        width: 16px;
        margin-left: 5px;
      }
    }
  }
  @media (max-width: 350px) {
    /* 手机端适配样式 */
    .content {
      width: 310px;
      border-radius: 2px;
      opacity: 1;
      background: #f9f9f9;
      box-sizing: border-box;
      border: 0.25px solid #1b79ff;
      animation: glow 1s ease-in-out infinite alternate;
      margin: 0 auto;
      margin-bottom: 20px !important;
      padding: 5px 0 5px 12px;
      .list {
        div {
          padding-bottom: 0px;
          line-height: 0px;
          text-align: left;
          display: flex;
          span:nth-child(1) {
            font-size: 10px;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0em;
            font-variation-settings: "opsz" auto;
            font-feature-settings: "kern" on;
            color: #7e7e7e;
            width: 93px;
            display: inline-block;
            text-align: left;
          }
          span {
            font-size: 10px;
            font-weight: 350;
            line-height: normal;
            letter-spacing: 0em;

            font-variation-settings: "opsz" auto;
            font-feature-settings: "kern" on;
            color: #000000;
          }
          .ip {
            display: inline-block;
            overflow-wrap: break-word;
            width: 180px;
          }
        }
      }

      img {
        width: 16px;
        margin-left: 5px;
      }
    }
    .footer {
      font-size: 10px;
    }
  }
  /* 关键帧动画 */
  @keyframes glow {
    from {
      box-shadow: 0 0 3px #3498db;
    }
    to {
      box-shadow: 0 0 8px #3498db;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    height: 30px;
    background: #f6f6f6;
    font-size: 13px;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    color: #000000;
    line-height: 30px;
    span {
      cursor: pointer;
    }
  }
  @media (max-width: 767px) {
    /* 手机端适配样式 */
    .footer {
      font-size: 11px;
    }
  }

  /deep/ .el-input__wrapper {
    border-radius: 2px;
    border: 1px solid #1b79ff;
    box-shadow: 0 0 0 1px #1b79ff;
    line-height: 0px;
  }
  /deep/ .el-input__inner {
    font-weight: bold;
    color: #000000;
    font-size: 16px;
  }
  /deep/ .el-input__inner::-webkit-input-placeholder {
    font-size: 12px;
  }
}
</style>
<style>
.el-message--warning .el-message__content {
  width: 240px;
}
</style>